
import * as imgproxyRuntime$wKR3_45dat9_cYyQZw3vl_45_MR1h0I1gxo9TIZPS89QHHE from '/app/shop/app/imgproxy-image-provider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imgproxy",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['imgproxy']: { provider: imgproxyRuntime$wKR3_45dat9_cYyQZw3vl_45_MR1h0I1gxo9TIZPS89QHHE, defaults: {"regex":"^(?<origin>^https?:\\/\\/.*?)\\/files\\/(?<path>media\\/.*)$"} }
}
        