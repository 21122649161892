import { generateUrl, type Options } from "@imgproxy/imgproxy-js-core";
import type { ImageOptions, ProviderGetImage } from "@nuxt/image";

export const getImage: ProviderGetImage = (
  src: string,
  options: ImageOptions,
) => {
  const regex = new RegExp(options.regex);

  if (!regex.test(src)) {
    return { url: src };
  }

  const matches = regex.exec(src);
  const groups = matches?.groups as { origin: string; path: string };

  let path = `/files/${groups.path}`;
  if (
    Object.entries(options.modifiers as object).some(
      ([, value]) => value !== undefined,
    )
  ) {
    const proxyOptions: Options = {};

    if (
      options.modifiers?.width &&
      options.modifiers?.height &&
      options.modifiers?.fit === "cover"
    ) {
      proxyOptions.resize = {
        width: options.modifiers.width,
        height: options.modifiers.height,
        resizing_type: "fill",
      };
      if (options.modifiers?.gravity) {
        proxyOptions.gravity = { type: options.modifiers.gravity };
      }
    } else if (options.modifiers?.width && options.modifiers?.height) {
      proxyOptions.size = {
        width: options.modifiers.width,
        height: options.modifiers.height,
      };
    } else {
      if (options.modifiers?.width) {
        proxyOptions.width = options.modifiers.width;
      }
      if (options.modifiers?.height) {
        proxyOptions.height = options.modifiers.height;
      }
    }

    path = generateUrl({ value: groups.path, type: "plain" }, proxyOptions);
    if (path.startsWith("/plain")) path = "/z:1" + path;
    path = "/imgproxy" + path.replace("/plain", "");
  }

  return {
    url: groups.origin + path,
  };
};
